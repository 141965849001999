import { getUserRole } from '@NURIHAUS-Dev/core-business';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getUserList } from 'api/account/User';

import { selectAccount } from 'features/redux/selectors/accounts';

type ListUserType = 'all' | 'member' | 'creator' | 'verified' | 'rejected' | 'partner' | 'brand';

export const useUserListData = ({
  page,
  page_size = 25,
  search = '',
  type = 'all',
  branchFilter,
  onSuccess,
}: {
  page: number;
  page_size?: number;
  search?: string;
  type?: ListUserType;
  branchFilter?: number;
  onSuccess?: (data: any) => void;
}) => {
  const account = useSelector(selectAccount);
  const { access_token, user } = account.userInfo;
  const { branch } = user;
  const userType = getUserRole(user);
  const br = branchFilter || userType === 'super' ? undefined : branch;

  const { data, isLoading, isError, error, isFetching } = useQuery<any, AxiosError>(
    ['userList', { tab: type, searchKeyword: search, page, branch: br }],
    () => {
      return getUserList(access_token, {
        page,
        page_size,
        search,
        type,
        branch: br,
      });
    },
    {
      keepPreviousData: true,
      onSuccess,
    }
  );

  return { data, isLoading, isError, error, isFetching };
};

export const useUserInfiniteListData = ({
  page_size = 25,
  search = '',
  type = 'all',
  branchFilter,
  onSuccess,
}: {
  page_size?: number;
  search?: string;
  type?: ListUserType;
  branchFilter?: number | null;
  onSuccess?: (data: any) => void;
}) => {
  const account = useSelector(selectAccount);
  const { access_token, user } = account.userInfo;
  const { branch } = user;
  const userType = getUserRole(user);
  const br = branchFilter === null ? undefined : userType === 'super' ? undefined : branchFilter || branch;

  const { data, isLoading, isError, error, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery<any, AxiosError>(
    ['userInfiniteList', { tab: type, searchKeyword: search, branch: br }],
    ({ pageParam = 1 }) => {
      return getUserList(access_token, {
        page: pageParam,
        page_size,
        search,
        type,
        branch: br,
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const { next } = lastPage;

        return next ? pages.length + 1 : undefined;
      },
      onSuccess,
    }
  );

  return { data, isLoading, isError, error, isFetching, fetchNextPage, hasNextPage };
};
