import { Fragment, RefObject, useState } from 'react';

import { getUserRole } from '@NURIHAUS-Dev/core-business';
import { Grid } from '@mui/material';
import { useDebounce, useIntersection } from '@nurihaus/react';
import { Text, colors } from '@nurihaus/web-design-system';
import { BrandListProvider, useBrandListContext } from 'providers/pages/chat/BrandListContext';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { useUserInfiniteListData } from 'hooks/user/queries/list';

import MenuIcon from 'components/Icons/MenuIcon';
import Block from 'components/layout/common/Block';

import { selectAccount } from 'features/redux/selectors/accounts';

import { UserDetails } from 'types/account/internal';

const TITLE_HEIGHT = 64;
const TAB_HEIGHT = 64;
const TAB_WIDTH = 150;
const SEARCH_HEIGHT = 90;
const ANIMATION_DURATION = '0.3s';

interface BrandListProps {
  selectBrand: (brand: UserDetails) => void;
  selectedBrand: UserDetails | null;
}

const ChatStaffBrandList = ({ selectBrand, selectedBrand }: BrandListProps) => {
  return (
    <BrandListProvider>
      <BrandListContent selectBrand={selectBrand} selectedBrand={selectedBrand} />
    </BrandListProvider>
  );
};

const BrandListContent = ({ selectBrand, selectedBrand }: BrandListProps) => {
  const { expanded, collapse, tab } = useBrandListContext();
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 500);

  const {
    data: brandList,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useUserInfiniteListData({
    type: tab,
    page_size: 10,
    search: debouncedSearchInput,
    branchFilter: null,
  });

  const { intersectionTarget } = useIntersection(fetchNextPage, {}, hasNextPage ?? false);

  const handleSearchInput = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  return (
    <StyledGrid item $expanded={expanded}>
      <Block height="830px" padding="0">
        <Container>
          <ToolBar searchInput={searchInput} handleSearchInput={handleSearchInput} />
          <CampaignListSection $expanded={expanded}>
            {isLoading
              ? Array.from({ length: 5 }, (_, index) => index + 1).map((index) => {
                  return <SingleListSkeleton key={index} />;
                })
              : brandList?.pages
                  .flatMap((page) => page?.results)
                  .map((user: UserDetails) => (
                    <ItemContainer
                      key={user.id}
                      $expanded={expanded}
                      $isSelected={selectedBrand?.id === user.id}
                      onClick={() => {
                        collapse();
                        selectBrand(user);
                      }}
                    >
                      {!expanded && (
                        <IdWrapper $expanded={expanded}>
                          <Text size="sm" weight="semibold" text={user.nickname} />
                        </IdWrapper>
                      )}
                      <BrandInfo>
                        <Thumbnail src={user.profile ?? user.get_profile} alt="campaign thumbnail" />
                        <BrandDetail $expanded={expanded}>
                          <BrandTitleWrapper $expanded={expanded}>
                            <Text size="sm" weight="medium" text={user.nickname} wordBreak="break-word" />
                          </BrandTitleWrapper>
                        </BrandDetail>
                      </BrandInfo>
                    </ItemContainer>
                  ))}
            <IntersectionTarget ref={intersectionTarget as RefObject<HTMLDivElement>} />
          </CampaignListSection>
        </Container>
      </Block>
    </StyledGrid>
  );
};

const SingleListSkeleton = () => {
  return (
    <SkeletonContainer>
      <CircleSkeleton />
      <div>
        <TextSkeleton width="100px" />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <TextSkeleton width="50px" />
          <SmallRectSkeleton />
        </div>
      </div>
    </SkeletonContainer>
  );
};

const ToolBar = ({
  searchInput,
  handleSearchInput,
}: {
  searchInput: string;
  handleSearchInput: (searchInput: string) => void;
}) => {
  const { expanded, toggleExpanded, tab, handleTab } = useBrandListContext();
  const account = useSelector(selectAccount);

  const shouldDisplayTab = getUserRole(account.userInfo.user) === 'super';

  const tabItems = [
    { id: 'brand', text: 'Brand' },
    { id: 'partner', text: 'Partner' },
  ] as const;

  return (
    <Fragment>
      <TitleSection $expanded={expanded}>
        <TitleWrapper>
          <Text text="Brand" size="lg" weight="semibold" />
        </TitleWrapper>
        <FoldButton $expanded={expanded} onClick={toggleExpanded}>
          <MenuIcon type={expanded ? 'chevron_left' : 'chevron_right'} width={18} height={18} fill="violet39" />
        </FoldButton>
      </TitleSection>
      {shouldDisplayTab && (
        <Tabs $expanded={expanded}>
          {tabItems.map((item) => (
            <Tab key={item.id} onClick={() => handleTab(item.id)}>
              <Text
                text={item.text}
                size="lg"
                weight={tab === item.id ? 'bold' : 'medium'}
                color={tab === item.id ? 'purple39' : 'gray71'}
              />
            </Tab>
          ))}
          <TabIndicator $activeTab={tab} />
        </Tabs>
      )}
      <SearchWrapper $expanded={expanded}>
        <SearchInput
          placeholder="Search By Brand Name"
          value={searchInput}
          onChange={(e) => handleSearchInput(e.target.value)}
        />
      </SearchWrapper>
    </Fragment>
  );
};

export default ChatStaffBrandList;

const StyledGrid = styled(Grid)<{ $expanded: boolean }>`
  width: ${({ $expanded }) => (!$expanded ? '142px' : '404px')};
  transition: all ${ANIMATION_DURATION} ease-in-out;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TitleSection = styled.div`
  width: 100%;
  height: ${TITLE_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;

  > div:first-child {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
    transition: opacity ${ANIMATION_DURATION} ease-in-out;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FoldButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.grayE4};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? '16px' : '50%')};
  transform: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'none' : 'translateX(50%)')};
  transition: all ${ANIMATION_DURATION} ease-in-out;

  &:hover {
    background-color: ${colors.purple39};
    border-color: ${colors.purple39};

    svg {
      path {
        stroke: white;
      }
    }
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? `${SEARCH_HEIGHT}px` : '0px')};
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
  overflow: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'visible' : 'hidden')};
  transition: height ${ANIMATION_DURATION} ease-in-out, opacity ${ANIMATION_DURATION} ease-in-out,
    overflow ${ANIMATION_DURATION} ease-in-out;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 14px;
  background-color: ${colors.grayFa};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  ::placeholder {
    color: ${colors.grayA1};
  }
`;

const CampaignListSection = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) =>
    `calc(100% - ${$expanded ? TITLE_HEIGHT + TAB_HEIGHT + SEARCH_HEIGHT : TITLE_HEIGHT}px)`};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.grayE4};
  transition: height ${ANIMATION_DURATION} ease-in-out;

  > * {
    flex-shrink: 0;
    min-height: fit-content;
  }
`;

const ItemContainer = styled.div<{ $expanded: boolean; $isSelected: boolean }>`
  width: 100%;
  height: ${({ $expanded }) => ($expanded ? '148px' : '98px')};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${colors.grayE4};
  position: relative;
  background-color: ${({ $isSelected }) => ($isSelected ? colors.grayFa : colors.white)};
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: ${colors.grayFa};
  }
`;

const IdWrapper = styled.div<{ $expanded: boolean }>`
  height: ${({ $expanded }) => ($expanded ? '20px' : '36px')};
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const BrandInfo = styled.div`
  display: flex;
  align-items: center;
`;

const BrandDetail = styled.div<{ $expanded: boolean }>`
  width: calc(100% - 78px);
  padding-left: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  visibility: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
`;

const Thumbnail = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 4px;
  object-fit: cover;
`;

const BrandTitleWrapper = styled.div<{ $expanded: boolean }>`
  width: 100%;
  padding-right: 48px;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  max-height: ${({ $expanded }) => ($expanded ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const IntersectionTarget = styled.div`
  background-color: transparent;
  height: 10px;
  margin-top: -10px;
`;

const Tabs = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? `${TAB_HEIGHT}px` : '0px')};
  border-bottom: 1px solid ${colors.grayE4};
  gap: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
  overflow: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'visible' : 'hidden')};
  transition: height ${ANIMATION_DURATION} ease-in-out, opacity ${ANIMATION_DURATION} ease-in-out,
    overflow ${ANIMATION_DURATION} ease-in-out;
`;

const Tab = styled.button`
  width: ${TAB_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  transition: color ${ANIMATION_DURATION} ease-in-out;
`;

const TabIndicator = styled.div`
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: ${TAB_WIDTH}px;
  height: 2px;
  background-color: ${colors.purple39};
  transform: translateX(
    ${({ $activeTab }: { $activeTab: 'brand' | 'partner' }) =>
      $activeTab === 'brand' ? `${-(TAB_WIDTH + 10)}px` : `${10}px`}
  );
  transition: transform ${ANIMATION_DURATION} ease-in-out;
`;

// 스켈레톤 애니메이션 설정
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 애니메이션 적용한 컨테이너
const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 116px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards; // 페이드인 애니메이션 적용
`;

// 원형 스켈레톤 (아바타용)
const CircleSkeleton = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  margin-right: 16px;
`;

// 텍스트 스켈레톤
const TextSkeleton = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 20px;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  border-radius: 4px;
`;

// 작은 직사각형 스켈레톤 (국기용)
const SmallRectSkeleton = styled.div`
  width: 20px;
  height: 15px;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  border-radius: 2px;
  margin-left: 8px;
`;
