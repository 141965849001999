import { AxiosError } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import {
  getBenefitList,
  getChatBenefitList,
  getChatBenefitListStaff,
  getChatQuestList,
  getChatQuestListStaff,
  getQuestList,
} from 'api/campaign';
import { getEarnListApi, getSpendListApi } from 'api/lounge';

import { selectAccount } from 'features/redux/selectors/accounts';

import { Campaign } from 'types/campaign/internal';
import {
  BenefitList,
  CampaignStatus,
  ChatBenefitList,
  ChatBenefitListStaff,
  ChatQuestList,
  ChatQuestListStaff,
  QuestList,
} from 'types/campaign/remote';
import { PaginationType } from 'types/common';

// v1

export const useCampaignListData = ({
  campaign,
  page,
  tab,
  query,
  username,
  page_size = 25,
  ordering,
}: {
  campaign: 'benefit' | 'quest' | string;
  page: number;
  tab: string;
  query?: any;
  ordering?: string;
  page_size?: number;
  username?: string;
}) => {
  const params = { campaign, page, tab, query, ordering, username };
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    switch (campaign) {
      case 'quest': {
        return getEarnListApi(access_token, { query, ordering, page, page_size, tab, username });
      }
      default: {
        return getSpendListApi(access_token, { query, ordering, page, page_size, tab, username });
      }
    }
  };

  return useQuery<PaginationType<Campaign>, AxiosError>(['campaignList', params], getDataFn, {
    keepPreviousData: true,
  });
};

export const useInfiniteCampaignListData = (
  campaign: 'earn' | 'spend',
  params: { query: string; username?: string; branch?: number }
) => {
  const { query, username, branch } = params;
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = ({ pageParam = 1 }) => {
    switch (campaign) {
      case 'earn': {
        return getEarnListApi(access_token, {
          page: pageParam,
          tab: 'all',
          query,
          page_size: 6,
          ordering: '-created',
          username,
          branch,
        });
      }
      case 'spend': {
        return getSpendListApi(access_token, {
          page: pageParam,
          tab: 'all',
          query,
          page_size: 6,
          ordering: '-created',
          username,
        });
      }
    }
  };

  const {
    data,
    fetchNextPage,
    isLoading: isCampaignListLoading,
    isFetched,
    refetch,
    isRefetching,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery<PaginationType<Campaign>, AxiosError>(
    ['campaignList-infinite', { campaign, query, username }],
    getDataFn,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.next) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  return { data, isCampaignListLoading, isFetched, fetchNextPage, refetch, isRefetching, hasNextPage, isFetching };
};

// v2

type CampaignResponseType<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? QuestList['Get']['Response']
  : BenefitList['Get']['Response'];

type ParamsType<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? QuestList['Get']['QueryParams']
  : BenefitList['Get']['QueryParams'];

export const useCampaignList = <T extends 'quest' | 'benefit'>({
  type,
  params,
  branch,
  status,
}: {
  type: T;
  params: Pick<ParamsType<T>, 'page' | 'page_size' | 'search' | 'order'>;
  branch?: number;
  status?: CampaignStatus;
}) => {
  const filters = [status && `status:${status}`, branch && `branch_id:${branch}`].filter(Boolean).join('+');

  const getDataFn = async (): Promise<CampaignResponseType<T>> => {
    switch (type) {
      case 'quest':
        return getQuestList({ query: { ...params, filters } }) as unknown as CampaignResponseType<T>;
      case 'benefit':
        return getBenefitList({ query: { ...params, filters } }) as unknown as CampaignResponseType<T>;
      default:
        throw new Error('Invalid type');
    }
  };

  return useQuery<CampaignResponseType<T>, AxiosError>(
    ['campaignList-v2', { type, params: { ...params, branch, status } }],
    getDataFn,
    {
      keepPreviousData: true,
    }
  );
};

type ChatCampaignResponseType<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? ChatQuestList['Get']['Response']
  : ChatBenefitList['Get']['Response'];

type ChatParamsType<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? ChatQuestList['Get']['QueryParams']
  : ChatBenefitList['Get']['QueryParams'];

export const useInfiniteChatCampaignList = <T extends 'quest' | 'benefit'>({
  type,
  params,
}: {
  type: T;
  params: Pick<ChatParamsType<T>, 'page_size'>;
}) => {
  const getDataFn = async ({ pageParam = 1 }): Promise<ChatCampaignResponseType<T>> => {
    switch (type) {
      case 'quest':
        return getChatQuestList({ query: { ...params, page: pageParam } }) as unknown as ChatCampaignResponseType<T>;
      case 'benefit':
        return getChatBenefitList({ query: { ...params, page: pageParam } }) as unknown as ChatCampaignResponseType<T>;
      default:
        throw new Error('Invalid type');
    }
  };

  return useInfiniteQuery<ChatCampaignResponseType<T>, AxiosError>(
    ['chatCampaignList-v2', { type, params }],
    getDataFn,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.next) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

type ChatCampaignResponseTypeStaff<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? ChatQuestListStaff['Get']['Response']
  : ChatBenefitListStaff['Get']['Response'];

type ChatParamsTypeStaff<T extends 'quest' | 'benefit'> = T extends 'quest'
  ? ChatQuestListStaff['Get']['QueryParams']
  : ChatBenefitListStaff['Get']['QueryParams'];

export const useInfiniteChatCampaignListStaff = <T extends 'quest' | 'benefit'>({
  type,
  params,
}: {
  type: T;
  params: Pick<ChatParamsTypeStaff<T>, 'page_size' | 'user_ids'>;
}) => {
  const getDataFn = async ({ pageParam = 1 }): Promise<ChatCampaignResponseTypeStaff<T>> => {
    switch (type) {
      case 'quest':
        return getChatQuestListStaff({
          query: { ...params, page: pageParam },
        }) as unknown as ChatCampaignResponseTypeStaff<T>;
      case 'benefit':
        return getChatBenefitListStaff({
          query: { ...params, page: pageParam },
        }) as unknown as ChatCampaignResponseTypeStaff<T>;
      default:
        throw new Error('Invalid type');
    }
  };

  return useInfiniteQuery<ChatCampaignResponseTypeStaff<T>, AxiosError>(
    ['chatCampaignListStaff-v2', { type, params }],
    getDataFn,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.next) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};
