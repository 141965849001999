import { requestApi, requestWithAuth } from 'utils/common/axios-utils';

import { CampaignType } from 'types/campaign/internal';
import {
  BenefitBulkCompleteAPI,
  BenefitBulkDropAPI,
  BenefitStatisticsByIdAPI,
  GetCampaignSummaryApiPayload,
  GetCarriersResponse,
  GetCompleteStateListCSVPayload,
  GetEarnListApiPayload,
  GetEarnListApiResponse,
  GetSpendListApiPayload,
  GetSpendListApiResponse,
  GetStateListCSVPayload,
  GetStateListPayload,
  GetStateListResponse,
  PostAdditionalFormPayload,
  PostBulkShippingInfoPayload,
  PostBulkShippingInfoResponse,
  QuestBulkCompleteAPI,
  QuestBulkDropAPI,
  QuestStatisticsByIdAPI,
  StateHistoryDetailApi,
  StateHistoryListApi,
} from 'types/campaign/remote';
import { PaginationType } from 'types/common';
import { GetCreditOrderListPayload, GetJellyOrderListPayload } from 'types/myJelly/remote';

export const createSpendApi = async (access_token: string, createInfo: FormData) => {
  return await requestWithAuth(
    { method: 'post', url: `/lounge/spend/create/`, data: createInfo, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};
export const deleteSpendApi = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'delete', url: `/lounge/spend/delete/${id}/` }, access_token);
};

export const createEarnApi = async (access_token: string, createInfo: FormData) => {
  return await requestWithAuth(
    { method: 'post', url: `/lounge/point/create/`, data: createInfo, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};
export const deleteEarnApi = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'delete', url: `/lounge/point/delete/${id}/` }, access_token);
};

export const getEarnListApi = async (access_token: string, params: GetEarnListApiPayload) => {
  const { query, ordering, page, page_size, tab, username, branch } = params;

  return await requestWithAuth<PaginationType<GetEarnListApiResponse>>(
    {
      method: 'get',
      url: `/lounge/point/list/?${ordering ? `ordering=${ordering}` : 'ordering=-created'}${
        page ? `&page=${page}` : `%page=1`
      }${page_size ? `&page_size=${page_size}` : `&page_size=1`}${query !== '' ? `&query=${query}` : ''}${
        tab !== 'all' ? `&status=${tab}` : ''
      }${username ? `&username=${username}` : ''}${branch ? `&branch=${branch}` : ''}`,
    },
    access_token
  );
};

export const getSpendListApi = async (access_token: string, params: GetSpendListApiPayload) => {
  const { query, ordering, page, page_size, tab, username } = params;

  return await requestWithAuth<PaginationType<GetSpendListApiResponse>>(
    {
      method: 'get',
      url: `/lounge/spend/list/?${ordering ? `ordering=${ordering}` : `ordering=-created`}${
        page ? `&page=${page}` : `&page=1`
      }${page_size ? `&page_size=${page_size}` : `&page_size=1`}${query !== '' ? `&query=${query}` : ''}${
        tab !== 'all' ? `&status=${tab}` : ''
      }${username ? `&username=${username}` : ''}`,
    },
    access_token
  );
};

export const getSpendDetailApi = async (access_token: string, id: number) => {
  if (!id) return 'id is invalid';

  return await requestWithAuth({ method: 'get', url: `/lounge/spend/detail/${id}/` }, access_token);
};

export const getEarnDetailApi = async (access_token: string, id: number) => {
  if (!id) return 'id is invalid';

  return await requestWithAuth({ method: 'get', url: `/lounge/point/detail/${id}/` }, access_token);
};

export const getStateList = async ({ access_token, type, id, page, page_size, options }: GetStateListPayload) => {
  const extraQuery = options
    ? Object.keys(options).reduce((acc, cur) => {
        if (!options?.[cur as keyof GetStateListPayload['options']]) return acc;

        return acc + `&${cur}=${options[cur as keyof GetStateListPayload['options']]}`;
      }, '')
    : '';

  const pageQuery = page !== undefined && page_size !== undefined ? `page=${page}&page_size=${page_size}` : '';

  return await requestWithAuth<PaginationType<GetStateListResponse>>(
    {
      method: 'get',
      url: `/lounge/state/list/?content_type__model=${type}&object_id=${id}&${pageQuery}${extraQuery || ''}`,
    },
    access_token
  );
};

export const getCampaignSummaryApi = async (access_token: string, { id, campaign }: GetCampaignSummaryApiPayload) => {
  const model = campaign === 'benefit' ? 'event' : campaign;

  return await requestWithAuth({ method: 'get', url: `/lounge/summary/${model}/${id}/` }, access_token);
};

export const getSpendState = async (access_token: string, id: number, stateId: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/state/${stateId}/?content_type__model=event&object_id=${id}&chat=true&user=true`,
    },
    access_token
  );
};

export const getEarnState = async (access_token: string, id: number, stateId: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/state/${stateId}/?content_type__model=quest&object_id=${id}&chat=true&user=true`,
    },
    access_token
  );
};

export const getSpendChatList = async (access_token: string, page: number, id: number) => {
  return await requestWithAuth(
    { method: 'get', url: `/lounge/chat/for/event/${id}/?page_size=8&page=${page || 1}` },
    access_token
  );
};
export const patchSpend = async (access_token: string, id: number, patchInfo: any) => {
  return await requestWithAuth(
    { method: 'patch', url: `/lounge/spend/update/${id}/`, data: patchInfo, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};
export const patchEarn = async (access_token: string, id: number, patchInfo: any) => {
  return await requestWithAuth(
    { method: 'patch', url: `/lounge/point/update/${id}/`, data: patchInfo, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const getAdditionalForm = async (access_token: string, id: number) => {
  if (id) {
    return await requestWithAuth({ method: 'get', url: `/lounge/additionalform/${id}/` }, access_token);
  } else {
    return { fields: [], id: '', title: '' };
  }
};

export const postAdditionalForm = async (access_token: string, createInfo: PostAdditionalFormPayload) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/lounge/additionalform/`,
      data: { title: createInfo.title, fields: createInfo.form.fields },
    },
    access_token
  );
};

export const patchAdditionalForm = async (access_token: string, formInfo: { id: number; fields: any }) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/lounge/additionalform/${formInfo.id}/`,
      data: { fields: formInfo.fields },
      'Content-Type': 'multipart/form-data',
    },
    access_token
  );
};

export const postJellyPack = async (access_token: string, formData: FormData) => {
  return await requestWithAuth(
    { method: 'post', url: `/lounge/jellypack/`, data: formData, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const postCreditPack = async (access_token: string, formData: FormData) => {
  return await requestWithAuth(
    { method: 'post', url: `/lounge/creditpack/`, data: formData, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const patchJellyPack = async (access_token: string, id: number, formData: FormData) => {
  return await requestWithAuth(
    { method: 'patch', url: `/lounge/jellypack/${id}/`, data: formData, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const patchCreditPack = async (access_token: string, id: number, formData: FormData) => {
  return await requestWithAuth(
    { method: 'patch', url: `/lounge/creditpack/${id}/`, data: formData, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const getJellyPackList = async (access_token: string) => {
  return await requestWithAuth({ method: 'get', url: `/lounge/jellypack/?page_size=20&page=1` }, access_token);
};

export const getCreditPackList = async (access_token: string) => {
  return await requestWithAuth({ method: 'get', url: `/lounge/creditpack/?page_size=20&page=1` }, access_token);
};

export const getJellyPack = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'get', url: `/lounge/jellypack/${id}/` }, access_token);
};

export const getCreditPack = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'get', url: `/lounge/creditpack/${id}/` }, access_token);
};

export const getJellyOrderList = async (access_token: string, params: GetJellyOrderListPayload) => {
  const { page, page_size, username, from, to, status } = params;

  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/jellyorder/?page=${page}&page_size=${page_size}${username ? `&username=${username}` : ''}${
        to ? `&to=${to}` : ''
      }${from ? `&from=${from}` : ''}${status ? `&status=${status}` : ''}`,
    },
    access_token
  );
};

export const getCreditOrderList = async (access_token: string, params: GetCreditOrderListPayload) => {
  const { page, page_size, username, from, to, status } = params;

  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/creditorder/?page=${page}&page_size=${page_size}${username ? `&username=${username}` : ''}${
        to ? `&to=${to}` : ''
      }${from ? `&from=${from}` : ''}${status ? `&status=${status}` : ''}`,
    },
    access_token
  );
};

export const postJellyOrder = async (
  access_token: string,
  jellyPackId: number,
  userId?: number,
  customInfo?: {
    created: string;
    deposit_date: string;
  }
) => {
  let data: any = { pack: jellyPackId };
  if (customInfo && userId) {
    data = { ...data, ...customInfo, user: userId };
  }

  return await requestWithAuth(
    { method: 'post', url: `/lounge/jellyorder/${customInfo ? `?complete=true` : ''}`, data },
    access_token
  );
};

export const postCreditOrder = async (
  access_token: string,
  creditPackId: number,
  userId?: number,
  customInfo?: {
    created: string;
    deposit_date: string;
  }
) => {
  let data: any = { pack: creditPackId };
  if (customInfo && userId) {
    data = { ...data, ...customInfo, user: userId };
  }

  return await requestWithAuth(
    { method: 'post', url: `/lounge/creditorder/${customInfo ? `?complete=true` : ''}`, data },
    access_token
  );
};

export const patchJellyOrder = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'patch', url: `/lounge/jellyorder/${id}/`, data: { a: 'a' } }, access_token);
};

export const patchCreditOrder = async (access_token: string, id: number) => {
  return await requestWithAuth({ method: 'patch', url: `/lounge/creditorder/${id}/`, data: { a: 'a' } }, access_token);
};

export const getCreateCategoryList = async (access_token: string) => {
  return await requestWithAuth({ method: 'get', url: `/lounge/category/` }, access_token);
};

export const completeCampaign = async (model: 'event' | 'quest' | 'market', id: number, access_token: string) => {
  return await requestWithAuth({ method: 'post', url: `/lounge/complete/${model}/${id}/`, data: {} }, access_token);
};

export const uploadImage = async (access_token: string, formData: FormData) => {
  return await requestWithAuth(
    { method: 'post', url: `/lounge/upload/`, data: formData, 'Content-Type': 'multipart/form-data' },
    access_token
  );
};

export const bulkAccept = async (access_token: string, data: { ids: number[] }) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: '/lounge/accept/',
      data,
    },
    access_token
  );
};

export const completeApplyAndLeaveChat = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/lounge/state/complete/${id}/`,
      data: {},
    },
    access_token
  );
};

export const getCampaignStatics = async (
  access_token: string,
  params: { date_from?: string; date_to?: string; type: CampaignType | 'market' }
) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/${params.type}/statistics?${`${params.date_from ? `date_from=${params.date_from}` : ''}${
        params.date_to ? `&date_to=${params.date_to}` : ''
      }`}`,
      data: {},
    },
    access_token
  );
};

export const uploadUgcVideo = async (access_token: string, formData: FormData, fileName?: string) => {
  return requestWithAuth(
    {
      method: 'post',
      data: formData,
      url: `/lounge/files/ugc${fileName ? ',' + fileName : ''}/`,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  );
};

export const getCarriers = async (access_token: string) => {
  return requestWithAuth<GetCarriersResponse[]>(
    {
      method: 'get',
      url: 'lounge/carriers/',
    },
    access_token
  );
};

export const postShippingInfo = async (access_token: string, shippingInfo: any) => {
  return requestWithAuth(
    {
      method: 'post',
      data: shippingInfo,
      url: 'lounge/state/shipping/',
      'Content-Type': 'application/json',
    },
    access_token
  );
};

export const postBulkShippingInfo = async (access_token: string, bulkShippingInfo: PostBulkShippingInfoPayload[]) => {
  return requestWithAuth<PostBulkShippingInfoResponse>(
    {
      method: 'post',
      data: { packages: bulkShippingInfo },
      url: 'lounge/state/shipping/bulk/',
      'Content-Type': 'application/json',
    },
    access_token
  );
};

//v2 server

export const getStateListCSV = async (access_token: string, stateListCSVParams: GetStateListCSVPayload) => {
  return requestWithAuth(
    {
      method: 'get',
      url: `v2/csv/state/list/?object_id=${stateListCSVParams.object_id}&content_type_id=${
        stateListCSVParams.content_type_id
      }${stateListCSVParams.status_filters ? `&status_filters=${stateListCSVParams.status_filters}` : ''}${
        stateListCSVParams.carrier ? `&carrier=${stateListCSVParams.carrier}` : ''
      }${stateListCSVParams.follower_countries ? `&follower_countries=${stateListCSVParams.follower_countries}` : ''}${
        stateListCSVParams.user_type ? `&user_type=${stateListCSVParams.user_type}` : ''
      }`,
      'Content-Type': 'text/csv',
    },
    access_token
  );
};

export const getCompleteStateListCSV = async (
  access_token: string,
  stateListCSVParams: GetCompleteStateListCSVPayload
) => {
  return requestWithAuth(
    {
      method: 'get',
      url: `/v2/csv/state/list/completed/?object_id=${stateListCSVParams.object_id}&content_type_id=${stateListCSVParams.content_type_id}`,
      'Content-Type': 'text/csv',
    },
    access_token
  );
};

export const getQuestStatisticsByIdAPI = ({
  id,
}: QuestStatisticsByIdAPI['Get']['QueryParams']): QuestStatisticsByIdAPI['Get']['Response'] => {
  return requestApi({
    method: 'get',
    url: `/ops/lounge/quest/${id}/statistics`,
  });
};

export const getBenefitStatisticsByIdAPI = ({
  id,
}: BenefitStatisticsByIdAPI['Get']['QueryParams']): BenefitStatisticsByIdAPI['Get']['Response'] => {
  return requestApi({
    method: 'get',
    url: `/ops/lounge/event/${id}/statistics`,
  });
};

export const postQuestBulkCompleteByIdsAPI = ({
  object_id,
  state_ids,
}: QuestBulkCompleteAPI['Post']['QueryParams']) => {
  return requestApi({
    method: 'post',
    url: `/ops/lounge/statehistory/quest/bulk-complete/`,
    data: { object_id, state_ids },
  });
};

export const postQuestBulkDropByIdsAPI = ({ object_id, state_ids }: QuestBulkDropAPI['Post']['QueryParams']) => {
  return requestApi({
    method: 'post',
    url: `/ops/lounge/statehistory/quest/bulk-drop/`,
    data: { object_id, state_ids },
  });
};

export const postBenefitBulkCompleteByIdsAPI = ({
  object_id,
  state_ids,
}: BenefitBulkCompleteAPI['Post']['QueryParams']) => {
  return requestApi({
    method: 'post',
    url: `/ops/lounge/statehistory/event/bulk-complete/`,
    data: { object_id, state_ids },
  });
};

export const postBenefitBulkDropByIdsAPI = ({ object_id, state_ids }: BenefitBulkDropAPI['Post']['QueryParams']) => {
  return requestApi({
    method: 'post',
    url: `/ops/lounge/statehistory/event/bulk-drop/`,
    data: { object_id, state_ids },
  });
};

export const getStateHistoryListApi = (stateHistoryListParams: StateHistoryListApi['Get']['QueryParams']) => {
  const { object_id, page, page_size, filters, order, content_type_id } = stateHistoryListParams;

  return requestApi<StateHistoryListApi['Get']['Response']>({
    method: 'get',
    url: 'ops/lounge/statehistory',
    query: {
      object_id,
      content_type_id,
      filters,
      order,
      page,
      page_size,
    },
  });
};

export const getStateHistoryDetailApi = (stateHistoryDetailParams: StateHistoryDetailApi['Get']['QueryParams']) => {
  return requestApi<StateHistoryDetailApi['Get']['Response']>({
    method: 'get',
    url: `ops/lounge/statehistory/${stateHistoryDetailParams.state_id}/`,
  });
};
